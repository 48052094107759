import React, { useEffect, useState, lazy, Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/system';
import { Card, CardContent, CircularProgress, Typography } from '@mui/material';

const HurlPlugin = lazy(() => import('./HurlPlugin'));

const AssignmentView = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const hurl = queryParams.get('hurl');
    const component_id = queryParams.get('component_id');

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    const Background = styled('div')({
        backgroundColor: '#f5f5f5',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
    });

    const StyledCard = styled(Card)({
        backgroundColor: '#ffffff',
        borderRadius: '20px',
        padding: '20px',
        maxWidth: '550px',
        width: '100%',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    });

    return (
        <Background>
            <StyledCard>
                <CardContent>
                    <Suspense fallback={<CircularProgress />}>
                        <HurlPlugin hurl={hurl} component={{id: component_id}} />
                    </Suspense>
                </CardContent>
            </StyledCard>
        </Background>
    );
};

export default AssignmentView;