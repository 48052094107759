const translations = {
    en: {
        completed: "✅ Completed",
        continue: "Continue",
    },
    ru: {
        completed: "✅ Завершено",
        continue: "Продолжить",
    }
};

export function translate(languageCode, key) {
    return translations[languageCode]?.[key] || translations.en[key];
}
