import React from "react";
import { Card, CardContent, Step, Skeleton } from '@mui/material';
import { styled } from '@mui/system';

export default function CourseCardSkeleton({ themeParams }) {
    const StyledCard = styled(Card)({
        backgroundColor: themeParams.section_bg_color,
        color: themeParams.text_color,
        borderRadius: '20px',
        margin: '20px'
    });

    return (
        <StyledCard className="max-w-xl mx-auto mt-10">
            <CardContent className="flex flex-col items-center p-6">
                <p>
                    <Skeleton variant="text" width="60%" height={40} className="text-center mb-2"/>
                    <Skeleton variant="text" width="80%" height={24} className="text-center mb-4"/>
                    <Skeleton variant="text" width="40%" height={20} className="text-center mb-4"/>
                    <Skeleton variant="text" width="40%" height={20} className="text-center mb-4"/>
                </p>
                <p>
                    <Skeleton variant="text" width="60%" height={40} className="text-center mb-2"/>
                    <Skeleton variant="text" width="80%" height={24} className="text-center mb-4"/>
                    <Skeleton variant="text" width="40%" height={20} className="text-center mb-4"/>
                    <Skeleton variant="text" width="40%" height={20} className="text-center mb-4"/>
                </p>
                <p>
                    <Skeleton variant="text" width="60%" height={40} className="text-center mb-2"/>
                    <Skeleton variant="text" width="80%" height={24} className="text-center mb-4"/>
                    <Skeleton variant="text" width="40%" height={20} className="text-center mb-4"/>
                    <Skeleton variant="text" width="40%" height={20} className="text-center mb-4"/>
                </p>
                <p>
                    <Skeleton variant="text" width="60%" height={40} className="text-center mb-2"/>
                    <Skeleton variant="text" width="80%" height={24} className="text-center mb-4"/>
                    <Skeleton variant="text" width="40%" height={20} className="text-center mb-4"/>
                    <Skeleton variant="text" width="40%" height={20} className="text-center mb-4"/>
                </p>
                <p>
                    <Skeleton variant="text" width="60%" height={40} className="text-center mb-2"/>
                    <Skeleton variant="text" width="80%" height={24} className="text-center mb-4"/>
                </p>

            </CardContent>
        </StyledCard>
    );
}