// actions.js
export const setComponents = (components) => {
    return {
        type: 'SET_COMPONENTS',
        payload: components,
    };
};

export const setCompletedState = (completedState) => ({
    type: 'SET_COMPLETED_STATE',
    payload: completedState,
});