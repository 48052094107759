// reducers.js
const initialState = {
    components: [],
    completedState: 0,
};

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_COMPONENTS':
            return {
                ...state,
                components: action.payload,
            };
        case 'SET_COMPLETED_STATE':
            return {
                ...state,
                completedState: action.payload,
            };
        default:
            return state;
    }
};

export default rootReducer;
