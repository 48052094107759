import React, { useState, useEffect, lazy, Suspense, createContext } from "react";
import './App.css';
import { useLocation } from 'react-router-dom';
import useWebApp from './twa/useWebApp';
import axios from 'axios';
import CourseCardSkeleton from './CourseCardSkeleton';
import { styled } from '@mui/system';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Card, CardContent, Typography } from "@mui/material";
import PluginSkeleton from "./PluginSkeleton";
import AssignmentView from "./AssignmentView";
import { setCompletedState } from "./actions";
import { useDispatch } from "react-redux";
import { translate } from "./translations"; // Import the translate function

/**
 * Axios HTTP Request defaults
 */
axios.defaults.baseURL = "https://api.coob.app";
axios.defaults.withCredentials = true;

const Plugin = lazy(() => import('./Plugin'));

export const AppContext = createContext();

export default function MyApp() {
    const location = useLocation();
    const dispatch = useDispatch();

    const queryParams = new URLSearchParams(location.search);
    const courseId = queryParams.get('course_id');
    const stepID = queryParams.get('step_id');
    const botID = queryParams.get('bot_id');
    const chatID = queryParams.get('chat_id');
    const messageID = queryParams.get('message_id');
    let nextStepID = queryParams.get('next_step_id');

    // Add the assignment view parameter
    const assignmentView = queryParams.get('assignment_view');

    const [componentLoading, setComponentLoading] = useState(true);
    const [components, setComponents] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null); // New state for error message
    const [dark, setDark] = useState(false);
    const [themeParams, setThemeParams] = useState({});
    const [accessToken, setAccessToken] = useState(null);
    const [user, setUser] = useState(null);
    const [language, setLanguage] = useState('en'); // Add language state

    let WebApp = useWebApp();

    useEffect(() => {
        if (assignmentView) {
            return;
        }

        if (WebApp) {
            WebApp.ready();
            WebApp.setHeaderColor('secondary_bg_color');
            WebApp.disableVerticalSwipes();

            if (WebApp.platform !== "macos") {
                WebApp.enableClosingConfirmation();
            }

            setComponentLoading(true);

            WebApp.onEvent('viewportChanged', () => WebApp.expand());

            const colorScheme = WebApp.colorScheme || 'light';
            setDark(colorScheme === 'dark');
            setThemeParams(WebApp.themeParams);

            axios.post("/v1/auth/login/telegram", {
                course_id: courseId,
                web_app_data: WebApp.initData,
            }, {
                withCredentials: true,
            }).then(response => {
                setAccessToken(response.data.data.access_token);
                setUser(response.data.data.user);
                setLanguage(response.data.data.user.language || 'en'); // Set user language

                return axios.get(`/v2/steps/${stepID}/components`, {
                    headers: {
                        'Authorization': `Bearer ${response.data.data.access_token}`,
                        'X-CURRENT-STEP-ID': `${stepID}`,
                    }
                });
            }).then(response => {
                setComponents(response.data.data);
                setComponentLoading(false);

                let components = response.data.data;
                let notCompletedTotal = components.filter(c => c.answer_required && !c.is_completed).length;
                const anyStepCompleted = components.some(c => c.is_step_completed);

                if (!anyStepCompleted) {
                    if (notCompletedTotal > 0) {
                        dispatch(setCompletedState(notCompletedTotal));
                    }
                } else {
                    dispatch(setCompletedState(-1));
                }

                if (!WebApp.isExpanded) {
                    WebApp.expand();
                }

            }).catch(error => {
                setErrorMessage("You do not have access to this content.");

                WebApp = null;
                setComponentLoading(false);
            });
        }

    }, [WebApp, assignmentView, courseId, stepID, dispatch]);

    useEffect(() => {
        if (WebApp && accessToken) {
            WebApp.MainButton.onClick(function() {
                sendWebAppData(accessToken);
            });
        }
    }, [WebApp, accessToken]);

    useEffect(() => {
        if (WebApp && components && components.length > 0) {
            const totalCompleted = components.filter(c => c.is_completed).length;
            const anyStepCompleted = components.some(c => c.is_step_completed);

            WebApp.MainButton.show();

            const completedText = translate(language, 'completed');
            const continueText = translate(language, 'continue');

            if (anyStepCompleted) {
                WebApp.MainButton.setParams({
                    text: completedText,
                    color: themeParams.bg_color,
                    text_color: themeParams.text_color,
                    is_active: false,
                });
            } else if (totalCompleted === components.length) {
                WebApp.MainButton.setParams({
                    text: continueText,
                    color: themeParams.button_color,
                    text_color: themeParams.button_text_color,
                    is_active: true,
                });
            } else {
                WebApp.MainButton.setParams({
                    text: continueText,
                    color: themeParams.bg_color,
                    text_color: themeParams.text_color,
                    is_active: false,
                });
            }
        }
    }, [WebApp, components, language, themeParams]);

    function sendWebAppData(accessToken) {
        let messageType = "next";
        if (nextStepID === "") {
            messageType = "finish";
        }

        WebApp.MainButton.showProgress();

        const requestData = {
            type: messageType,
            user_id: user ? user.id : "",
            bot_id: botID,
            step_id: stepID,
            next_step_id: nextStepID,
            chat_id: chatID,
            message_id: messageID
        };

        const config = {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        };

        axios.post("/v1/bots/webapp", requestData, config)
            .then(response => {
                console.log("Successfully sent:", response.data);

                WebApp.MainButton.hideProgress();
                WebApp.close();
            })
            .catch(error => {
                WebApp.MainButton.hideProgress();
                WebApp.showAlert("error: " + error.response.data.data.message);
                console.error("Error sending data:", error);
            });
    }

    const StyledContainer = styled('div')({
        backgroundColor: themeParams.secondary_bg_color,
        color: themeParams.text_color
    });

    if (!WebApp) {
        return null;
    }

    if (assignmentView) {
        return <AssignmentView />;
    }

    const theme = createTheme({
        palette: {
            mode: dark ? 'dark' : 'light',
        },
    });

    const StyledCard = styled(Card)({
        backgroundColor: themeParams.section_bg_color,
        color: themeParams.text_color,
        borderRadius: '20px',
        margin: '20px'
    });

    const ScrollableContainer = styled('div')({
        overflowY: 'auto',
        height: '100vh',
        width: '100%',
        position: 'relative'
    });

    return (
        <ThemeProvider theme={theme}>
            <ScrollableContainer>
                <StyledContainer>
                    {componentLoading ? (
                        <CourseCardSkeleton themeParams={themeParams} />
                    ) : (
                        <StyledCard className="max-w-xl mx-auto mt-10">
                            <CardContent className="flex flex-col items-center p-6">
                                <Suspense fallback={
                                    <React.Fragment>
                                        {(components.length > 0 ? components : [{}]).map((_, index) => (
                                            <PluginSkeleton key={index} />
                                        ))}
                                    </React.Fragment>
                                }>
                                    {errorMessage ? (
                                        <Typography variant="h6" color="error" align="center" style={{ marginTop: '20px' }}>
                                            {errorMessage}
                                        </Typography>
                                    ) : null}

                                    {components.map((component) => (
                                        <Plugin
                                            accessToken={accessToken}
                                            component={component}
                                            components={components}
                                            isSyllabus={false}
                                            viewerWidthPercent={100}
                                            themeParams={themeParams}
                                            mode={dark ? 'dark' : 'light'}
                                            language={language} // Pass the language to the Plugin component
                                            showFullscreen={ component?.show_fullscreen }
                                        />
                                    ))}
                                </Suspense>
                            </CardContent>
                        </StyledCard>
                    )}
                </StyledContainer>
            </ScrollableContainer>
        </ThemeProvider>
    );
}
